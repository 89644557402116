export const colors = ["red", "green", "blue", "yellow", "orange", "purple"];

export type Configuration = {
  color: string;
  size: number;
  position: {
    x: number;
    y: number;
    z: number;
  };
}

export function getRandomColor(): string {
  return colors[Math.floor(Math.random() * colors.length)];
}

export function validateConfiguration(configuration: Configuration): null | Error {
  if (!colors.some(color => color === configuration.color)) {
    return new Error('color not in the allowed list');
  }
  if (typeof configuration.size !== "number" || isNaN(configuration.size)) {
    return new Error('size must be a positive number');
  }
  if (configuration.size < 0) {
    return new Error('size must be greater than 0');
  }
  if (configuration.size > 10) {
    return new Error('size must be less than 10');
  }
  if (typeof configuration.position !== "object") {
    return new Error('position must be an object');
  }
  if (typeof configuration.position.x !== "number" || isNaN(configuration.position.x)) {
    return new Error('position.x must be a number');
  }
  if (typeof configuration.position.y !== "number" || isNaN(configuration.position.y)) {
    return new Error('position.y must be a number');
  }
  if (typeof configuration.position.z !== "number" || isNaN(configuration.position.x)) {
    return new Error('position.z must be a number');
  }
  return null;
}

export type HTTPSignUpResponse = {

}
import { addSpaceV0Callback, removeSpaceV0Callback, SpaceV0 } from "@meta-web/space-v0";
import { createConfiguration, getConfiguration, signUp, } from "./configuration";
import { LoadedScriptV0, registerScriptV0, unregisterScriptV0 } from "@meta-web/scripts-v0";
import { Configuration, getRandomColor } from "../common";
import { ConfigurationInstance } from "./ConfigurationInstance";
import { KeyboardHelper } from "@meta-web/keyboard-helper";
import { isErrorWithStatusCode } from "@meta-web/configuration-durable-object";

const appName = "example-configurator";

const unregisterFns: Array<() => void> = [];
const thisScript = document.currentScript as HTMLScriptElement;

const scriptUrl = new URL(thisScript.src);
const id = scriptUrl.searchParams.get("id");

const spaceCallback = (spaceV0: SpaceV0) => {
  if (!id) {
    // This is in initial creation mode - ask the user to create a new configuration
    initialCreationMode(spaceV0, unregisterFns, thisScript);
    return;
  }

  loadExistingConfiguration(id, spaceV0, unregisterFns, thisScript);
}
addSpaceV0Callback(spaceCallback);
unregisterFns.push(() => removeSpaceV0Callback(spaceCallback));

let currentLoadedScript: LoadedScriptV0;
function doUnload() {
  for (const fn of unregisterFns) {
    fn();
  }
  unregisterScriptV0(currentLoadedScript);
  thisScript.remove();
}

currentLoadedScript = registerScriptV0({
  name: id ? idToName(id) : appName,
  url: thisScript.src
}, document.currentScript as HTMLScriptElement, () => {
  doUnload();
});

async function createNewUser() {
  try {
    return await signUp();
  } catch (error) {
    if (isErrorWithStatusCode(error)) {
      // The user already exists - just load the configuration
      console.error("error during sign up", error);
    } else {
      throw error;
    }
  }
}

async function initialCreationMode(spaceV0: SpaceV0, unregisterFns: Array<() => void>, thisScript: HTMLScriptElement) {
  console.log("initialCreationMode");

  let keyboardHelper: KeyboardHelper | null = new KeyboardHelper();
  let disposeKeyboardHelper = () => {
    if (keyboardHelper) {
      keyboardHelper.dispose();
      keyboardHelper = null;
    }
  }
  keyboardHelper.onKeyDown('c', async () => {
    disposeKeyboardHelper();

    const configuration: Configuration = {
      color: getRandomColor(),
      position: {
        x: 0,
        y: 0,
        z: 0,
      },
      size: Math.ceil(Math.random() * 5),
    };
    saveNewConfiguration(configuration, spaceV0, unregisterFns, thisScript);
  });
  unregisterFns.push(() => disposeKeyboardHelper);
}

function idToName(id: string) {
  return id.substring(id.length - 4, id.length - 1) + " (" + appName + ")";
}

async function saveNewConfiguration(initialConfiguration: Configuration, spaceV0: SpaceV0, unregisterFns: Array<() => void>, thisScript: HTMLScriptElement) {
  const {scene} = spaceV0;

  let response;
  try {
    response = await createConfiguration({
      configuration: initialConfiguration,
    });
    console.log("configuration created", initialConfiguration);

    const instance = new ConfigurationInstance(scene);
    unregisterFns.push(() => {
      instance.dispose();
    });

    const configurationState = response.configurationState;
    instance.applyConfigurationState(configurationState);

    thisScript.src = `${thisScript.src}?id=${configurationState.id}`;

    console.log("Unregistering existing script", currentLoadedScript);
    unregisterScriptV0(currentLoadedScript);

    console.log("Registering new script");
    currentLoadedScript = registerScriptV0({
      url: thisScript.src,
      name: idToName(configurationState.id)
    }, thisScript, () => {
      doUnload();
    });
  } catch (e) {
    if (isErrorWithStatusCode(e)) {
      console.error("Error creating configuration", e);
      if (e.statusCode === 401) {
        // Need to create a new user
        await createNewUser();
        await saveNewConfiguration(initialConfiguration, spaceV0, unregisterFns, thisScript);
      } else {
        // TODO - Show error message
      }
      return;
    }
    console.error("create configuration", e);
    return;
  }
}

async function loadExistingConfiguration(id: string, spaceV0: SpaceV0, unregisterFns: Array<() => void>, thisScript: HTMLScriptElement) {
  const {scene} = spaceV0;
  console.log("loadExistingConfiguration", id);

  let response;
  try {
    response = await getConfiguration(id);
  } catch (e) {
    console.error(e);
    return;
  }
  console.log("configuration", response);

  if (response) {
    const instance = new ConfigurationInstance(scene);
    unregisterFns.push(() => {
      instance.dispose();
    });
    const configurationState = response.configurationState;
    instance.applyConfigurationState(configurationState);
  }
}

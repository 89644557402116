import { THREE } from "@meta-web/space-v0";
import { Configuration, getRandomColor } from "../common";
import { KeyboardHelper } from "@meta-web/keyboard-helper";
import { updateConfiguration } from "./configuration";
import { ConfigurationState } from "@meta-web/configuration-durable-object";

export class ConfigurationInstance {
  private sphere: THREE.Mesh<THREE.SphereGeometry, THREE.MeshStandardMaterial>;
  private scene: THREE.Scene;
  private id: string | null = null;
  private ownedBySelf: boolean = false;
  private keyboardHelper: KeyboardHelper;

  constructor(scene: THREE.Scene) {
    this.scene = scene;
    this.sphere = new THREE.Mesh(
      new THREE.SphereGeometry(1, 32, 32),
      new THREE.MeshStandardMaterial({side: THREE.DoubleSide}),
    );
    this.sphere.castShadow = true;
    this.sphere.receiveShadow = true;
    this.scene.add(this.sphere);

    this.keyboardHelper = new KeyboardHelper();
    this.keyboardHelper.onKeyDown('e', async () => {
      if (this.id && this.ownedBySelf) {
        const response = await updateConfiguration(this.id, {
          configuration: {
            color: getRandomColor(),
            size: Math.ceil(Math.random() * 5),
            position: {
              x: (Math.random() * 10) - 5,
              y: (Math.random() * 5),
              z: (Math.random() * 10) - 5,
            },
          },
        });
        if (response) {
          this.applyConfigurationState(response.configurationState);
        }
      }
    });
  }

  applyConfigurationState(configurationState: ConfigurationState<Configuration>) {
    this.id = configurationState.id;
    this.ownedBySelf = configurationState.ownedByCurrentUser;

    const configuration = configurationState.configuration;
    this.sphere.position.set(configuration.position.x, configuration.position.y, configuration.position.z);
    this.sphere.material.color = new THREE.Color(configuration.color);
    this.sphere.scale.set(configuration.size, configuration.size, configuration.size);
  }

  dispose() {
    this.scene.remove(this.sphere);
    this.keyboardHelper.dispose();
  }
}
import { Configuration, HTTPSignUpResponse, } from "../common";
import { HTTPBodies, jsonApiRequest } from "@meta-web/configuration-durable-object";

const thisScriptSrcURL = new URL((document.currentScript as HTMLScriptElement).src);
const host = `${thisScriptSrcURL.protocol}//${thisScriptSrcURL.host}`;

export async function createConfiguration(request: HTTPBodies<Configuration>['HTTPCreateConfigurationRequest']): Promise<HTTPBodies<Configuration>['HTTPGetConfigurationResponse']> {
  return jsonApiRequest<HTTPBodies<Configuration>['HTTPCreateConfigurationResponse']>(
    'POST',
    `${host}/api/v1/create`,
    request,
  );
}

export async function signUp(): Promise<HTTPSignUpResponse> {
  return jsonApiRequest<HTTPSignUpResponse>(
    'POST',
    `${host}/api/v1/signup`,
  );
}

export async function getConfiguration(id: string): Promise<HTTPBodies<Configuration>['HTTPGetConfigurationResponse']> {
  return jsonApiRequest<HTTPBodies<Configuration>['HTTPGetConfigurationResponse']>(
    'GET',
    `${host}/api/v1/state/${id}`,
  );
}

export async function updateConfiguration(id: string, request: HTTPBodies<Configuration>['HTTPUpdateConfigurationRequest']): Promise<HTTPBodies<Configuration>['HTTPUpdateConfigurationResponse']> {
  return jsonApiRequest<HTTPBodies<Configuration>['HTTPUpdateConfigurationResponse']>(
    'PUT',
    `${host}/api/v1/state/${id}`,
    request,
  );
}
